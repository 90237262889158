import { PageColourfulSection } from "../components/PageColourfulSection";
import { PageContainer } from "../components/PageContainer";
import { PageLayout } from "../components/PageLayout";
import { PageParticleSectionJumbotron } from "../components/PageParticleSectionJumbotron";
import { PageSectionContent } from "../components/PageSectionContent";
import { PageSectionSplit } from "../components/PageSectionSplit";
import { SectionCallToAction } from "../components/SectionCallToAction";
import { SectionPartners } from "../components/SectionPartners";
import { SectionServices } from "../components/SectionServices";
import { SectionText } from "../components/SectionText";
import { LondonSkylineImage } from "../components/SiteImage";
import React from "react";

const SolutionsPage = () => {
  return (
    <PageLayout transparentHeader>
      <PageParticleSectionJumbotron>Our Solutions</PageParticleSectionJumbotron>
      <SectionText heading="Empower your business with our bespoke solutions.">
        Our clients are at the heart of what we do. Keep up with your businesses
        ever-changing demands, from managed cloud services to on-site networking
        and infrastructure management. Our solutions accelerate your business
        with cutting-edge technology.
      </SectionText>
      <PageSectionSplit
        left={
          <PageColourfulSection className="h-full flex items-center">
            <div className="py-36 w-full h-full">
              <PageContainer>
                <PageSectionContent
                  className="text-white"
                  tagClass="text-white"
                  tag="Always connected"
                  heading="Accelerate your enterprise with cloud computing"
                >
                  <p>
                    It is now more important than ever that your employees are
                    connected to the corporate network, cloud productivity tools
                    and communication tools.
                  </p>
                  <p>
                    We provide a 100% SLA for your mission-critical services to
                    ensure your employees always have access to the tools they
                    need to maximise productivity and communication.
                  </p>
                </PageSectionContent>
              </PageContainer>
            </div>
          </PageColourfulSection>
        }
        right={<LondonSkylineImage />}
      />
      <SectionServices
        className=""
        cardClass="border-2 rounded p-6 border-gray transition-colors h-full"
        fillColour="fill-black"
        strokeColour="stroke-black"
      />
      <SectionPartners />
      <SectionCallToAction>
        Discover what goSystem can do for you
      </SectionCallToAction>
    </PageLayout>
  );
};

export default SolutionsPage;
